const getPalette = (theme) => {
  return {
    type: "light",
    primary: { main: "#324043", hover: "#6CB949" },
    secondary: { main: "#324043", hover: "#6CB949" },
    accent: "#324043",
    info: {
      main: "#324043",
    },
    error: {
      main: "#FF0040",
    },
    white: {
      main: "#FFF",
    },
  };
};

export default getPalette;
