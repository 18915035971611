import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Esher Tandoori is a well-established Indian restaurant located in the
        charming town of Esher, Surrey. The restaurant offers a diverse range of
        traditional and contemporary Indian dishes, including mouth-watering
        tandoori dishes, delicious curries, and flavourful biryanis. The menu
        features a variety of vegetarian and non-vegetarian options, catering to
        different dietary requirements.
      </Typography>

      <Typography variant="body1" paragraph>
        Esher Tandoori also offers a takeaway service for those who prefer to
        enjoy their meals at home. The restaurant uses high-quality ingredients
        and traditional cooking techniques to ensure that their dishes are
        bursting with flavour. Overall, Esher Tandoori is a must-visit for
        anyone looking for a delicious Indian dining experience in Esher,
        London.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return (
    <>
      <Typography variant="body1" paragraph>
        Careers at Esher Tandoori
      </Typography>
      <Typography variant="body1" paragraph>
        Browse the job openings at Esher Tandoori where you can find your
        perfect fit in a dynamic and vibrant culinary environment.
      </Typography>
      <Typography variant="body1" paragraph>
        <a
          href="https://esher-tandoori.softmehr.co.uk/"
          target="_blank"
          rel="noreferrer"
          className={cssStyles.textPrimary}
          style={{ textDecoration: "underline" }}
        >
          Browse Job Openings
        </a>
      </Typography>
    </>
  );
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
